import { GetServerSideProps } from 'next'
import { paths } from '@/constants'
import { RegionContextProvider } from '@/contexts/RegionContext'
import { Seo } from '@/layout/Seo'
import { DefaultLayout, NextPageWithLayout } from '@/layouts'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getServerSidePropsErrorHandler, getUrlFromThisFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { getBasePath } from '@/utils/sitemap/base'
import { useTranslate } from '@/utils/translate/translate-client'
import { loadTranslations } from '@/utils/translate/translate-server'
import { AppView } from '@/views/AppView'

interface AppDownloadPageProps {
  locale: string
}

const AppDownloadPage: NextPageWithLayout<AppDownloadPageProps> = ({ locale }) => {
  const { t } = useTranslate('app-promo')
  const basePath = `${getBasePath(locale)}`
  const url = `${basePath}${paths.app.index}`
  const title = t('angelStudiosAppDownloadTitle', 'Angel App: Watch Anytime. Anywhere. | Angel Studios')
  const description = t(
    'angelStudiosAppDownloadDescription',
    'The Angel app is the home of record-shattering stories that amplify light. In the Angel app users can watch full episodes, cast to their television, Pay it Forward to fund future seasons of shows they love and buy official merchandise. Download the app below for free.',
  )
  return (
    <>
      <Seo
        canonical={url}
        description={description}
        openGraph={{ title, description, url }}
        path={paths.app.index}
        title={title}
      />
      <RegionContextProvider>
        <AppView />
      </RegionContextProvider>
    </>
  )
}

AppDownloadPage.getLayout = (page) => {
  return <DefaultLayout>{page}</DefaultLayout>
}

export const getServerSideProps: GetServerSideProps = async ({ params }) =>
  getServerSidePropsErrorHandler({ requestUrl: getUrlFromThisFilePath(params) }, async () => {
    const locale = getLocaleFromParams(params)

    return {
      props: {
        locale,
        ...(await loadTranslations(locale, ['common', 'app-promo', 'watch'])),
      },
    }
  })

export default AppDownloadPage
