import { useMemo } from 'react'
import { ApolloClient, gql, useQuery } from '@apollo/client'
import { GetViewerRegionQuery } from '@/types/codegen-federation'
import { overrideOrDefaultRegion } from '@/utils/location'

const getViewerRegionQuery = gql`
  query getViewerRegion {
    viewer {
      region
    }
  }
`

interface UseUserRegionResult {
  loading: boolean
  region: string
}

export function useUserRegion(): UseUserRegionResult {
  const { data, loading } = useQuery<GetViewerRegionQuery>(getViewerRegionQuery, {
    errorPolicy: 'all',
  })
  const result = useMemo(() => ({ loading, region: overrideOrDefaultRegion(data?.viewer?.region) }), [data, loading])
  return result
}

export async function getUserRegion(client: ApolloClient<object>): Promise<string> {
  const { data } = await client.query<GetViewerRegionQuery>({
    query: getViewerRegionQuery,
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
  return overrideOrDefaultRegion(data?.viewer?.region)
}
